import { MarcaService } from '@shared/services/marca.service';
/* Libreria */
import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';

/* App */
import { ClienteTransporte, Canal, SubCanal, ZonaTransporte, Categoria, Supervisor } from './../../../models';
import { ClienteTransporteService, UnidadTransporteService, ZonaTransporteService, CategoriaService, CanalService, SubCanalService, SupervisorService } from './../../../services';
import { GeneralProvider } from './../../../providers';
import { rangoFechasActual, dateToString, Cookies, C } from './../../../commons';
import { LoadingAnimationComponent } from '../../../../shared/components';

/* Utils */
import { PrimeUtils } from '../../../commons/prime-utils';
import { Marca } from '@shared/models/marca';
import { OperacionService } from '@shared/services/operacion.service';
import { Operacion } from '@shared/models/operacion';

@Component({
  selector: 'busqueda-multiple',
  templateUrl: './multiple.component.html',
  styleUrls: ['./multiple.component.scss'],
  providers: [ClienteTransporteService, UnidadTransporteService, CanalService, SubCanalService, ZonaTransporteService, CategoriaService, MarcaService, SupervisorService, OperacionService]
})
export class MultipleComponent implements OnInit {
  @Output() change: EventEmitter<HttpParams> = new EventEmitter<HttpParams>();
  @Input() tipoReporte: string = null;
  lang: any;
  usuarioId: string;

  @ViewChild(LoadingAnimationComponent)
  loadingComponent: LoadingAnimationComponent;

  //Variables Filtros
  formFiltros: FormGroup;
  listClienteTransporte: Array<SelectItem>;
  listCanal: Array<SelectItem>;
  listSubCanal: Array<SelectItem>;
  listZonaTransporte: Array<SelectItem>;
  listCategoria: Array<SelectItem>;
  listTipoTarea: Array<SelectItem>;
  listMarca: Array<SelectItem>;
  listSupervisor: Array<SelectItem>;
  listOperacion: Array<SelectItem>;
  currentUser: any;
  //Header
  titleHeaderPage: string;

  flagVisibility: boolean;
  flagSupervisor: boolean;

  constructor(private general: GeneralProvider,
    private translate: TranslateService,
    private clienteTransporteService: ClienteTransporteService,
    private unidadTransporteService: UnidadTransporteService,
    private canalService: CanalService,
    private subCanalService: SubCanalService,
    private zonaTransporteService: ZonaTransporteService,
    private categoriaService: CategoriaService,
    private marcaService: MarcaService,
    private supervisorService: SupervisorService,
    private operacionService: OperacionService) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.usuarioId = this.currentUser.usuarioId;
    let rangoMes = rangoFechasActual(new Date(), '2D', 'D');

    this.formFiltros = new FormGroup({
      fechaInicio: new FormControl(rangoMes.inicio),
      fechaFin: new FormControl(rangoMes.fin),
      clientesTransporte: new FormControl(null),
      canales: new FormControl(null),
      subCanales: new FormControl(null),
      zonasTransporte: new FormControl(null),
      categorias: new FormControl(null),
      tiposTarea: new FormControl(null),
      tipoPeriodo: new FormControl(null),
      supervisorId: new FormControl(null),
      isReporteRadioControl: new FormControl(null),
      marcas: new FormControl(null),
      supervisores: new FormControl(null),
      operacionesId: new FormControl(null)
    });
    this.flagVisibility = true;
  }

  ngOnInit() {
    this.inicializarDatos();
    this.cargarDatos();
  }

  inicializarDatos() {

    this.flagSupervisor = !this.currentUser.esSupervisor &&
      !this.currentUser.esEmpresaTransporteAdministrador &&
      (this.tipoReporte === "viaje" || this.tipoReporte === "tarea") ? true : false;

    let _language = Cookies.getCookie("LANGUAGE");
    this.lang = PrimeUtils.getCalendarLanguage(_language);

    //Filtros
    this.listClienteTransporte = [];
    this.listCanal = [];
    this.listSubCanal = [];
    this.listZonaTransporte = [];
    this.listCategoria = [];
    this.listTipoTarea = [];
    this.listMarca = [];
    this.listSupervisor = [];
    this.listOperacion = [];

    //Constantes y/o Etiquetas
    this.listTipoTarea = this.general.getListaTipoTarea();
  }

  cargarDatos() {
    let listObservables = new Array<Observable<any>>();
    listObservables.push(this.clienteTransporteService.listar());
    listObservables.push(this.canalService.listar());
    listObservables.push(this.zonaTransporteService.listar());
    listObservables.push(this.categoriaService.listar());
    listObservables.push(this.marcaService.listar());
    listObservables.push(this.supervisorService.listar());
    if(this.tipoReporte === "viaje" || this.tipoReporte === "tarea"){
      listObservables.push(this.operacionService.listar());
    }



    //Ejecutamos todas las llamadas y cuando se termine ocultamos el loading
    Observable.forkJoin(listObservables).subscribe(
      results => {
        //Clientes de Transporte
        let clientesTransporte = <Array<ClienteTransporte>>results[0];
        this.listClienteTransporte = [];
        for (let o of clientesTransporte) {
          this.listClienteTransporte.push({ label: o.nombre, value: o.clienteTransporteId });
        }

        //Canales
        let canales = <Array<Canal>>results[1];
        this.listCanal = [];
        for (let o of canales) {
          this.listCanal.push({ label: o.nombre, value: o.canalId });
        }

        //Zonas de Transporte
        let zonasTransporte = <Array<ZonaTransporte>>results[2];
        this.listZonaTransporte = [];
        for (let o of zonasTransporte) {
          this.listZonaTransporte.push({ label: o.nombre, value: o.zonaTransporteId });
        }

        //Categorias
        let categorias = <Array<Categoria>>results[3];
        this.listCategoria = [];
        for (let o of categorias) {
          this.listCategoria.push({ label: o.nombre, value: o.categoriaId });
        }

        //Marcas
        let marcas = <Array<Marca>>results[4];
        this.listMarca = [];
        for (let o of marcas) {
          this.listMarca.push({ label: o.descripcion, value: o.marcaId });
        }

        //Supervisores
        let supervisores = <Array<Supervisor>>results[5];
        this.listSupervisor = [];
        for (let o of supervisores) {
          this.listSupervisor.push({ label: o.usuario.nombre, value: o.supervisorId });
        }
        
        //Operaciones
        if(this.tipoReporte === "viaje" || this.tipoReporte === "tarea"){
          let operaciones = <Array<Operacion>>results[6];
          for(let o of operaciones){
            this.listOperacion.push({label: o.descripcion, value: o.operacionId});
          }
        }

      },
      error => {
        console.log(error);
      },
      () => {
        this.loadingComponent.ocultarLoading();
      });

    this.titleHeaderPage = this.general.getConfiguracionPersonalizada(C.ETIQUETA_CONFIGURABLE.CONF_ETIQUETA_MARCA);

  }


  //Funciones Canal
  onChangeCanal(event): void {

    this.loadingComponent.mostrarLoading();
    this.subCanalService.listarMultiple(event.value).subscribe(
      (data: Array<SubCanal>) => {
        this.listSubCanal = [];
        for (let o of data) {
          this.listSubCanal.push({ label: o.nombre, value: o.subCanalId });
        }
        //Mantener la seleccion de los sub canales
        if (this.formFiltros.value.subCanales != null) {
          let valuesSubCanal = new Array<number>();
          valuesSubCanal = this.formFiltros.value.subCanales.filter((id) => {
            for (let subcanal of data) {
              if (id == subcanal.subCanalId) {
                return true;
              }
            }
            return false;
          });
          this.formFiltros.controls.subCanales.setValue(valuesSubCanal);
        }

        this.onChangeCombo();
      }
    );
    this.loadingComponent.ocultarLoading
  }
  //Funciones Componente
  onChangeCombo(accionable?) {
    this.loadingComponent.mostrarLoading();

    if (this.tipoReporte === 'tarea') {
      if (accionable === "fechaInicio") {
        this.formFiltros.get("fechaFin")
          .patchValue(this.procesarFechaFin(this.formFiltros.get("fechaInicio").value, this.formFiltros.get("fechaFin").value, 30));
      } else if (accionable == "fechaFin") {
        this.formFiltros.get("fechaInicio")
          .patchValue(this.procesarFechaInicio(this.formFiltros.get("fechaInicio").value, this.formFiltros.get("fechaFin").value, 30));
      }
    }

    let params: HttpParams = this.getParams();
    this.change.emit(params);
    this.loadingComponent.ocultarLoading();
  }

  setValueControl(key: string, value: Array<Number>) {
    let control = this.formFiltros.controls[key];
    if (control) {
      control.setValue(value);
    }
  }

  setValueFecha(key: string, value: Date) {
    let control = this.formFiltros.controls[key];
    if (control) {
      control.setValue(value);
    }
  }

  getParams(): HttpParams {
    let params = new HttpParams();
    let datos = this.formFiltros.value;

    if (datos.fechaInicio != null) {
      params = params.append("fechaInicio", dateToString(datos.fechaInicio));
    }
    if (datos.fechaFin != null) {
      params = params.append("fechaFin", dateToString(datos.fechaFin));
    }
    if (datos.clientesTransporte != null && datos.clientesTransporte.length) {
      params = params.append("clientesTransporte", datos.clientesTransporte.toString());
    }
    if (datos.canales != null && datos.canales.length) {
      params = params.append("canales", datos.canales.toString());
    }
    if (datos.subCanales != null && datos.subCanales.length) {
      params = params.append("subcanales", datos.subCanales.toString());
    }
    if (datos.zonasTransporte != null && datos.zonasTransporte.length) {
      params = params.append("zonasTransporte", datos.zonasTransporte.toString());
    }
    if (datos.categorias != null && datos.categorias.length) {
      params = params.append("categorias", datos.categorias.toString());
    }
    if (datos.tiposTarea != null && datos.tiposTarea.length) {
      params = params.append("tiposTarea", datos.tiposTarea.toString());
    }
    if (datos.tipoPeriodo != null) {
      params = params.append("tipoPeriodo", datos.tipoPeriodo);
    }
    if (datos.supervisorId != null) {
      params = params.append("supervisorId", datos.supervisorId);
    }
    if (datos.isReporteRadioControl != null) {
      params = params.append("isReporteRadioControl", datos.isReporteRadioControl);
    }
    if (datos.marcas != null && datos.marcas.length) {
      params = params.append("marcas", datos.marcas.toString());
    } else {
      if (this.currentUser.marca != null && this.currentUser.marca.marcaId != null) {
        params = params.append("marcas", this.currentUser.marca.marcaId);
      }
    }
    if (datos.supervisores != null && datos.supervisores.length) {
      params = params.append("supervisores", datos.supervisores.toString());
    }
    
    if(datos.operacionesId != null && datos.operacionesId.length){
      params = params.append("operacionesId",datos.operacionesId.toString());
    }

    params = params.append("usuarioId", this.usuarioId);

    return params;
  }

  private procesarFechaFin(fechaInicio, fechaFin, rangoDias): Date {
    let fechaInicio1 = moment(fechaInicio);
    let fechaFin1 = moment(fechaFin);
    let fechaInicioDate: Date = new Date(fechaInicio);
    if (fechaFin1.diff(fechaInicio1, 'days') > rangoDias) {
      return new Date(moment(fechaInicioDate).add(rangoDias, 'd').format());
    }
    return new Date(fechaFin);
  }

  private procesarFechaInicio(fechaInicio, fechaFin, rangoDias): Date {
    let fechaInicio1 = moment(fechaInicio);
    let fechaFin1 = moment(fechaFin);
    let fechaFinDate: Date = new Date(fechaFin);
    if (fechaFin1.diff(fechaInicio1, 'days') > rangoDias) {
      return new Date(moment(fechaFinDate).subtract(rangoDias, 'd').format());
    }
    return new Date(fechaInicio);
  }

}
