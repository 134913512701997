import { Autorizacion, Funcionalidad } from '@shared/models';
import { environment } from './../../../environments/environment';

export namespace C {
    export const BASE: string = environment['apiUrl'];
    export const BASE_CHAT_URL: string = environment['chatWebUrl'];
    export const LINK_CLIENTE: string = environment['clienteUrl'];
    export const APP: string = 'ST_WEB';

    export const MESSAGE_OK: string = 'Ok';

    export const TIPO_GESTION_HUB: number = 1;

    //SHORT CONSTANTS
    export const APP_ST: string = 'ST';
    export const APP_DRIVER: string = 'ST_APP_DRIVER';
    export const APP_TASK: string = 'ST_APP_TASK';
    export const APP_PLANNER: string = 'PLANNER';

    //LONG CONSTANTS
    export const APP_ST_LONG: string = 'SMART TRACING';
    export const APP_PLANNER_LONG: string = 'PLANNER';
    export const APP_ST_TASK_LONG: string = 'SMART TRACING TASK';
    export const APP_ST_DRIVER_LONG: string = 'SMART TRACING DRIVER';

    export namespace CHAT_VARS {
        export const REST_PATH                                  : string = "/backs/chat-interactivo";
        export const SOCKET_PATH                                : string = "/backs/chat-interactivo/socket.io";
        export const EVENTO_UNIRSE_CHAT                         : string  = "join";
        export const EVENTO_SALIR_CHAT                          : string  = "leave";
        export const EVENTO_ENVIA_MENSAJE_SUPERVISOR            : string  = "envia-mensaje-supervisor";
        export const EVENTO_ERROR_MENSAJE_SUPERVISOR            : string  = "error-mensaje-supervisor";
        export const EVENTO_LLEGA_MENSAJE_TRANSPORTISTA         : string  = "llega-mensaje-transportista";
        export const EVENTO_ENVIA_MENSAJES_LIEDOS_SUPERVISOR    : string  = "envia-mensajes-leidos-supervisor";
        export const EVENTO_LLEGA_MENSAJES_LEIDOS_TRANSPORTISTA : string  = "llega-mensajes-leidos-transportista";
        export namespace ESTADO {
            export const ENVIADO: number = 1;
            export const LEIDO: number = 2;
        }
        export namespace TIPO {
            export const TEXTO: number = 1;
            export const IMAGEN: number = 2;
        }
    }

    export namespace REGEX{
        export const SHORT_DATE : RegExp = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/;// 20/12/2017
        export const LETTERS_NUMBERS : RegExp = /^[a-zA-Z0-9]*$/;// aA 1
        export const LETTERS_NUMBERS_SPACE : RegExp = /^[a-zA-Z0-9 ]*$/;// aA 1
    }

    export namespace COMSATELCOLORS{
        export const WHITE : string = '#FFFFFF';
        export const BLACK : string = '#000000';
        export const GRAY_SHAFT : string = '#3A3A3A';
        export const RED_MONZA : string = '#DA0209';
        export const RED_TRINIDAD : string = '#E84700';
        export const RED_BRIGHT : string = '#FD6D73';
        export const RED : string = '#FF0000';
        export const GREEN_CUCUMBER : string = '#79AD6A';
        export const GREEN_GRAYISH : string = '#1ABC9C';
        export const BLUE_MARINE : string = '#2D4A66';
        export const YELLOW_GOLD : string = '#F7C800';
        export const BLUE_BRIGHT : string = '#6295E2';
        export const CYAN_STRONG : string = '#48C9B0';
        export const ORANGE_VIVID : string = '#FD9249';
        export const BLUE_GRAYISH : string = '#B1B2BF';
        export const BLUE_GRAYISH_2 : string = '#3B7BDB';
        export const BLUE_DARK_GRAYISH : string = '#A2A4B0';
        export const MAGENTA_GRAYISH: string = '#D6D5D6';
        export const GRAY_BLACK: string = '#2C2C2C';
        export const GRAY_VERY_LIGHT: string = '#DBDBDB';
        export const GRAY_89 : string = '#E3E3E3';
    }

    export namespace CHARTCOLORS {
        export const RANDOM: string [] = ['#3B7BDB','#FC4D54','#F7A405','#24A154','#7168B3','#3B3EAC','#0099C6','#DD4477','#66AA00','#B82E2E','#316395','#994499','#22AA99','#AAAA11','#6633CC','#E67300','#8B0707','#329262','#5574A6','#3B3EAC'];
        export const SOLICITUD_PEDIDO: string [] = ['#B558F6', '#29CB97', '#FEC400', '#FF9212', '#4072EE'];
        export const red : string = 'rgb(255, 99, 132)';
        export const orange: string = 'rgb(255, 159, 64)';
        export const yellow: string = 'rgb(255, 205, 86)';
        export const green: string = 'rgb(75, 192, 192)';
        export const blue: string = '#3366CC';
        export const purple: string = 'rgb(153, 102, 255)';
        export const grey: string = 'rgb(201, 203, 207)';
        export const red_solid : string = '#FC4D54';
    };

    export namespace FORMATOFECHA {
        export const DIA_SIN_CERO : string = 'd';
        export const MES_SIN_CERO : string = 'M';
        export const ANIO : string = 'yyyy';
        export const FECHA : string = 'dd/MM/yyyy';
        export const WEB : string = 'DD/MM/YYYY';
        export const SQL : string = 'YYYY-MM-DD';
        export const BD_LONG : string = "yyyy-MM-dd HH:mm:ss"
        export const FECHA_LONG : string = "dd/MM/yyyy HH:mm"
    }

    /* ESTADOS GENERALES */
    export namespace GENERAL{
        export namespace CRUD{
            export const REGISTRAR: string = "R";
            export const MODIFICAR: string = "M";
            export const ELIMINAR: string = "E";
            export const LOGO: string = "smart_tracing_eslogan.svg";
        }
        export namespace REGISTRO{
            export const ACTIVO: number = 1;
            export const ELIMINADO: number = 2;
            export const ELIMINADO_FISICO: number = 3;
        }
    }

    /* ESTADOS VIAJE */
    export namespace VIAJE{
        export namespace ESTADO{
            export const PROGRAMADO: number = 1;
            export const APT: number = 2;
            export const EN_RUTA: number = 3;
            export const FINALIZADO: number = 4;
            //VALIDAR
            export const INICIO_CARGA: number = 5;
            export const FIN_CARGA: number = 6;
            
            export const EN_HUB: number = 7;
            export const EN_PROGRAMACION: number = 8;
            export const EN_REPARTO: number = 9;
        }
        export namespace DESTINO{
            export const COMPANIA: string = "1";
            export const HUB_EMPRESA_TRANSPORTE: string = "2"
        }
        export namespace VEHICULO_ESTADO{
            export const PROGRAMADO: number = 1;
            export const EN_RUTA: number = 2;
            export const EN_ESPERA: number = 3;
            export const EN_CLIENTE: number = 4;
            export const EN_INICIO_DE_DESCARGA: number = 5;
            export const FINALIZADO: number = 6;
        }
        export namespace TIEMPO{
            export const A_TIEMPO: number = 1;
            export const RIESGO: number = 2;
            export const ATRASO: number = 3;
            export const REALIZADO: number = 4;

            export namespace COLORES{
                export const A_TIEMPO: string = '#1EBD9E';
                export const RIESGO: string = '#FC4D54';
                export const ATRASO: string = '#FD7C24';
                export const REALIZADO: string = '#3B7BDB';
            }
        }
        export namespace COLORES{
            export const PROGRAMADO: string = 'rgb(59, 123, 219)';
            export const RUTA: string = 'rgb(252, 77, 84)';
            export const INICIO_CARGA: string = 'rgb(252, 77, 100)';
            export const FIN_CARGA: string = 'rgb(173, 175, 150)';
            export const APT: string = 'rgb(173, 175, 188)';
            export const FINALIZADO: string = 'rgb(31, 189, 158)';
            export const EN_HUB: string = 'rgb(50, 168, 150)';
            export const EN_PROGRAMACION: string = 'rgb(31, 100, 250)';
            export const EN_REPARTO: string = 'rgb(120, 43, 58)';
        }
        export namespace MONITOREO {
            export const RECORRIDO_COLOR: string = '#A91B35';

            export namespace COLORES {
                export const COLOR1: string = '#557BFF';
                export const COLOR2: string = '#1B3BA9';
                export const COLOR3: string = '#044F40';
                export const COLOR4: string = '#7F0FA7';
                export const COLOR5: string = '#C700A7';
                export const COLOR6: string = '#ED4949';
                export const COLOR7: string = '#995618';
                export const COLOR8: string = '#F27400';
                export const COLOR9: string = '#373634';
                export const COLOR10: string = '#00B9D2';
            }
        }
    }

    export namespace ESTADO_VIAJE_FACTURACION{
        export namespace ESTADO {
            export const PENDIENTE: number = 1;
            export const POR_FACTURAR: number = 2;
            export const FACTURADO: number = 3;
        }
    }
    export namespace ESTADO_SOLICITUD_PEDIDO{
        export namespace ESTADO {
            export const RECIBIDO: number = 1;
            export const DIGITADO: number = 2;
            export const APROBADO: number = 3;
            export const FACTURADO: number = 4;
            export const DESPACHADO: number = 5;
        }
    }
    //SOLICITUD AUTORIZACION
    export namespace SOLICITUD_AUTORIZACION{
        export namespace AUTORIZACION{
            export const AUTORIZADO: number = 1;
            export const NO_AUTORIZADO: number = 2;
        }
        export namespace NOTIFICACION{
            export const ESTADO: number = 2;
        }
        export namespace PAQUETE{
            export const ESTADO: number = 2;
            export const ESTADO_A: number = 3;
            export const ESTADO_NA: number = 4;
            export const PENDIENTE_A: string = "PENDIENTE AUTORIZACIÓN";
        }
        export namespace GENERALES{
            export const S: string = "si";
            export const N: string = "no";
            export const TAREA: string = "Tarea";
        }
    }

        //PAQUETES
        export namespace PAQUETE{
            export namespace ESTADO_CARGA{
                export const POR_ESCANEAR: number = 1;
                export const PENDIENTE_AUTORIZACION: number = 2;
                export const AUTORIZADO: number = 3;
                export const NO_AUTORIZADO: number = 4;
                export const COMPLETADO: number = 5;
                export const PROGRAMADO: number = 6;
                export const RECOGIDO: number = 7;
                export const NO_ENCONTRADO: number = 8;
                export const NO_PROGRAMDO: number = 9;
                export const ENTREGADO: number = 10;
            }

            export namespace FORMA_CARGA_DESCARGA{
                export const MANUAL: string = "M";
                export const AUTOMATICO: string = "A";
            }
        }

    /* TIPOS DE EVIDENCIA  */
    export namespace EVIDENCIA{
        export namespace TIPO{
            export const TEXTO: number  = 1;
            export const TEXTO_IMAGENES: number = 2;
        }
    }
    /* ESTADOS PAQUETE */
    export namespace PAQUETE{
        export namespace ESTADO{
            export const PROGRAMADO: number = 6;
            export const RECOGIDO: number = 7;
            export const NO_ENCONTRADO: number = 8;
            export const NO_PROGRAMADO: number = 9;
        }
    }
    /* ESTADOS TAREA */
    export namespace TAREA{
        export namespace TIPO{
            export const RECOJO: number  = 0;
            export const ENTREGA: number = 1;
            export const RETORNO: number = 2;
        }

        export namespace ESTADO{
            export const PENDIENTE: number  = 1;
            export const EN_APT: number = 2;
            export const EN_RUTA: number = 3;
            export const EN_ESPERA: number = 4;
            export const EN_CLIENTE: number = 5;
            export const REALIZANDO_TAREA: number = 6;
            export const TERMINADO_TOTAL: number = 7;
            export const TERMINADO_PARCIAL: number = 8;
            export const RECHAZADO: number = 9;
            export const NO_REALIZADO: number = 10;
            export const NO_PROGRAMADO: number = 11;
            export const NO_AUTORIZADO: number = 12;
            export const EN_CAMINO: number = 13;
            export const POR_RECOGER:number = 14;
            export const RECOGIDO:number = 15;
            export const EN_HUB:number = 16;
            export const EN_ALMACEN:number = 17;
            export const EN_DESCARGA:number = 18;

        }

        export namespace COLORES{
            export const PENDIENTE: string  = '#FC4D54';
            export const EN_APT: string = '#7168B3';
            export const EN_RUTA: string = '#FD7921';
            export const EN_ESPERA: string = '#AFB1BD';
            export const EN_CLIENTE: string = '#3B7BDB';
            export const EN_CAMINO: string = '#FFD700';
            export const REALIZANDO_TAREA: string = '#51D684';
            export const TERMINADO_TOTAL: string = '#24A154';
            export const TERMINADO_PARCIAL: string = '#29C9B5';
            export const RECHAZADO: string = '#FF3434';
            export const NO_REALIZADO: string = '#424344';
        }

        export namespace RADIO_CONTROL{
            export const DENTRO: number = 1;
            export const FUERA: number = 2;
        }

        export namespace COLORES_ESTADO_ENTREGA{
            export const ENTREGADO: string = '#1ABC9C';
            export const ENTREGADO_RETRASO: string = '#D81F26';
            export const PENDIENTE: string = '#404145';
            export const PENDIENTE_RETRASO: string = '#FD761C';
            export const NO_REALIZADO: string = '#9E9E9E';
        }

        export namespace TIPO_UUID{
            export const INTERNO: number  = 1;
            export const EXTERNO: number = 2;
        }

        export namespace ESTADO_STRING{
            export const TO_NUMERO = {
                'TERMINADO TOTAL': 7,
                'TERMINADO PARCIAL': 8,
                'RECHAZADO': 9,
                'NO REALIZADO': 10
            }
        }
    }

    /* ESTADOS GUIA */
    export namespace GUIA{
        export namespace ESTADO{
            export const ENTREGA_PENDIENTE: number  = 1;
            export const ENTREGA_TOTAL: number = 2;
            export const ENTREGA_PARCIAL: number = 3;
            export const RECHAZO_TOTAL: number = 4;
        }
    }
    /* ESTADOS TARIFARIO */
    export namespace TARIFARIO{
        export namespace TIPO_TARIFA{
            export const RUTA: number  = 1;
            export const VIAJE: number = 2;
            export const SEDE: number  = 3;
            export const PUNTO_VISITA: number = 4;
        }
        export namespace MODO{
            export const FIJO: number = 1;
            export const PESO: number  = 2;
            export const DISTANCIA: number = 3;
            export const TIPO_VEHICULO: number = 4;
            export const RUTA: number = 5;
        }
    }
    /*ESTADOS COBRANZA */
    export namespace COBRANZA{
        export namespace ESTADO{
            export const POR_COBRAR: number  = 2;
            export const COBRO_PARCIAL: number = 3;
            export const COBRO_TOTAL: number = 4;
        }
    }

    /* FUNCIONALIDADES */
    export namespace FUNCIONALIDAD{
        export namespace SEGURIDAD{
            export const PERFIL : number = 4;
            export const USUARIO : number = 5;
            export const ACCESOS : number = 14;
            export const SUPERVISOR : number = 33;            
            export const REPORTE_ACTIVIDAD : number = 17;
            export const REPORTE_FUNCIONES_USADAS : number = 26;
            export const REPORTE_LOG : number = 54;
            export const REPORTE_CONSUMO_SMS : number = 59;
        }
        export namespace MANTENIMIENTO{
            export const CLIENTES : number = 16;
            export const ALMACEN : number = 34;
            export const EMPRESA_TRANSPORTE : number = 20;
            export const CANAL : number = 30;
            export const CATEGORIA : number = 31;
            export const COMPANIA : number = 32;
            export const ZONA_TRANSPORTE : number = 36;
            export const MOTIVO_RECHAZO : number = 35;
            export const CLIENTE_TRANSPORTE : number = 38;
            export const FORMA_PAGO : number = 39;
            export const MOTIVO_NO_AUTORIZACION : number = 40;
            export const TRANSPORTISTA : number = 64;
            export const UNIDAD_TRANSPORTE : number = 65;
            export const RUTA : number = 67;
            export const TARIFARIO : number = 73;
        }
        export namespace REPORTE{
            export const INCIDENTES : number = 21;
            export const MOTIVO_RECHAZO : number = 22;
            export const USO_FLOTA_PESO : number = 24;
            export const USO_FLOTA_CAPACIDAD_CONSOLIDADO : number = 44;
            export const ESPERA_APT : number = 27;
            export const EFECTIVIDAD_COBRANZA : number = 28;
            export const RECOJO : number = 29;
            export const VIAJE : number = 37;
            export const TAREAS : number = 41;
            export const ON_TIME : number = 42;
            export const ON_TIME_SEDE : number = 69;
            export const ON_TIME_HORA : number = 71;
            export const IN_FULL_PAQUETES : number = 70;
            export const ATRASOS : number = 18;
            export const RESANES : number = 171;
            export const ATENCION_PEDIDO : number = 172;
        }
        export namespace PROGRAMACION{
            export const IMPORTACION_VIAJE : number = 7;
            export const IMPORTACION_VIAJES_CLIENTES : number = 56;
            export const IMPORTACION_PAQUETE : number = 62;
            export const VIAJE : number = 9;
            export const ASIGNAR_SUPERVISOR : number = 23;
        }

        export const DASHBOARD : number = 8;

        export namespace CONTROL{
            export const ESTADOS_VIAJE : number = 11;
            export const INDICADORES_ESTADO : number = 12;
            export const INDICADORES_REPARTO : number = 13;
            export const INDICADOR_LEAD_TIME : number = 15;
            export const REPORTE_ATRASOS : number = 18;
            export const INDICADORES_DESPACHO : number = 19;
            export const INGRESO_SALIDA_UNIDAD : number = 25;
            export const ESTADOS_SOLICIUD_PEDIDO : number = 170;
        }
    }

    /* TIPO USUARIO */
    export namespace TIPO_USUARIO{
        export const INTERNO: number  = 1;
        export const EMPRESA_TRANSPORTE: number = 2;
        export const TRANSPORTISTA: number = 3;
        export const CLIENTE: number = 4;
    }

    /* TIPO PERFIL */
    export namespace TIPO_PERFIL{
        export const ADMINISTRADOR_SISTEMA = 1;
        export const SUPERVISOR_DESPACHO : number = 6;
        export const OPERADOR_SEGUIMIENTO_TRANSPORTISTA = 7;
        export const EMPRESA_TRANSPORTE = 8;
        export const EMPRESA_TRANSPORTE_TRANSPORTISTA = 16;
        export const ASISTENTE_DISTRIBUCION = 10;
        export const JEFE_DISTRIBUCION = 11;
        export const SUPERVISOR = 15;
        export const SUPER_USUARIO = 99;
    }

    export namespace FUNCIONALIDAD_LOG {
        export const USUARIO = "SEGURIDAD_USUARIO"
        export const EMPRESA_TRANSPORTE = "MANTENIMIENTO_EMPRESA_TRANSPORTE"
        export const CANAL = "MANTENIMIENTO_CANAL"
        export const MOTIVO = "MANTENIMIENTO_MOTIVO"
        export const COMPANIA = "MANTENIMIENTO_COMPANIA"
        export const FORMA_PAGO = "MANTENIMIENTO_FORMA_PAGO"
        export const CATEGORIA = "MANTENIMIENTO_CATEGORIA"
        export const ALMACEN = "MANTENIMIENTO_ALMACEN"
        export const CLIENTE_FINAL = "PROGRAMACION_CLIENTE_FINAL"
        export const TIPO_INCIDENTE = "MANTENIMIENTO_TIPO_INCIDENTE"
        export const ZONA_TRANSPORTE = "MANTENIMIENTO_ZONA_TRANSPORTE"
        export const VIAJE = "PROGRAMACION_VIAJE"
        export const DEVOLUCION = "PROGRAMACION_DEVOLUCION"
        export const CLIENTE_TRANSPORTE = "MANTENIMIENTO_CLIENTE_TRANSPORTE"
        export const ASIGNAR_SUPERVISOR = "PROGRAMACION_ASIGNAR_SUPERVISOR"  
        export const ESTADOS_VIAJE = "CONTROL_ESTADO_VIAJE_ASIGNAR_SUPERVISOR"
        export const ESTADO_VIAJE_I = "CONTROL_ESTADO_VIAJE_AGREGAR_INCIDENTE"  
        export const ESTADOS_VIAJE_OBSERVACION = "CONTROL_ESTADOS_VIAJE_OBSERVACION"   
        export const ESTADOS_VIAJE_GUIA = "CONTROL_ESTADOS_VIAJE_GUIA"
        export const IMPORTACION_VIAJE = "PROGRAMACION_IMPORTACION_VIAJES"  
        export const VIAJE_RECOJO = "PROGRAMACION_VIAJES_RECOJO"  
        export const CONFIGURACION = "SEGURIDAD_CONFIGURACION" 
        export const IMPORTACION_VIAJES_CLIENTES = "PROGRAMACION_IMPORTACION_VIAJES_CLIENTES"
        export const IMPORTACION_PAQUETES = "PROGRAMACION_IMPORTACION_PAQUETES"
        export const IMPORTACION_RESANES = "PROGRAMACION_IMPORTACION_RESANES"
        export const IMPORTACION_PRODUCTOS = "PROGRAMACION_IMPORTACION_PRODUCTOS"
        export const MOTIVO_NO_AUTORIZACION = "MANTENIMIENTO_MOTIVO_NO_AUTORIZACION"
        export const TRANSPORTISTA = "MANTENIMIENTO_TRANSPORTISTA"
        export const UNIDAD_TRANSPORTE = "MANTENIMIENTO_UNIDAD_TRANSPORTE"
        export const RUTA = "MANTENIMIENTO_RUTA"
        export const TARIFARIO = "MANTENIMIENTO_TARIFARIO"
        export const ESTADO_VIAJE_TRANSBORDO = "CONTROL_ESTADO_VIAJE_TRANSBORDO"
        export const NOTIFICACION_ALERTAS = "NOTIFICACION_ALERTAS"
        export const REGISTRAR_PRODUCTO_SOBRANTE = "CONTROL_ESTADO_VIAJE_GUIA_PRODUCTOS"
        export const CONFIRMAR_ENTREGA = "CONFIRMAR_ENTREGA"
        export const RECHAZAR_ENTREGA = "RECHAZAR_ENTREGA"
        export const ESTADOS_VIAJE_DETALLE_ESTADO_TAREA = "ESTADO_VIAJE_DETALLE_CAMBIAR_ESTADO_TAREA";
        export const ESTADOS_VIAJE_CAMBIAR_ESTADO = "ESTADO_VIAJE_CAMBIAR_ESTADO_VIAJE";
        export const DESBLOQUEO_MOVIL_ID = "DESBLOQUEO_MOVIL_ID";
        export const MOTIVO_ESTADO = "MOTIVO_ESTADO";
        export const SEGURIDAD_CAMBIO_ESTADO_VIAJE = "SEGURIDAD_CAMBIO_ESTADO_VIAJE";
        export const SEGURIDAD_CAMBIO_ESTADO_TAREA = "SEGURIDAD_CAMBIO_ESTADO_TAREA";
        export const PRODUCTO = "MANTENIMIENTO_PRODUCTO";
    }

    /* TIPO PLANTILLA */
    export namespace TIPO_PLANTILLA {
        export const CLIENTE = "CLIENTE";
        export const VIAJE_CLIENTE = "VIAJE/CLIENTE";
        export const VIAJE = "VIAJE";
    }

    /* CONFIGURACIONES DE IMPORTACION */
    export namespace CONFIGURACION_IMPORTACION {
        
        // IMPORTACION CLIENTES
        export namespace CLIENTE {
            export const COD_CLI = "COD_CLI";
            export const NOM = "NOM";
            export const TEL = "TEL";
            export const COR = "COR";
            export const COD_CAN = "COD_CAN";
            export const COD_SUB_CAN = "COD_SUB_CAN";
            export const CAT = "CAT";
            export const RUB = "RUB";
            export const TIP_TARIF = "TIP_TARIF";
            export const CEN_COS = "CEN_COS";
            export const RUT = "COD_RUT";
            export const TARIF = "TARIF";
            export const COD_SED = "COD_SED";
            export const NOM_SED = "NOM_SED";          
            export const REF = "REF";
            export const DIR = "DIR";
            export const LAT = "LAT";
            export const LON = "LON";
            export const UBI = "UBI";
            export const COD_MAR = "COD_MAR";
            export const LEA_TIME = "LEA_TIME";
            export const RAD_CON = "RAD_CON";
            export const TIE_DES = "TIE_DES";
            export const TIE_ESP = "TIE_ESP";
            export const NOM_CON = "NOM_CON";
            export const APE_CON = "APE_CON";
            export const TEL_CON = "TEL_CON";
            export const DOC_IDE_CON = "DOC_IDE_CON";
            export const COR_CON = "COR_CON";
            export const VEN_HOR_SED = "VEN_HOR_SED";
            export const VEN_HOR_INI_SED = "HOR_INI_SED";
            export const VEN_HOR_FIN_SED = "HOR_FIN_SED";
            export const VEN_HOR_INI_SED_2 = "HOR_INI_SED_2";
            export const VEN_HOR_FIN_SED_2 = "HOR_FIN_SED_2";
            
            export const CLI_NOM = "NOM";
            export const CLI_DIR = "DIR";
            export const NRO_PED = "NRO_PED";
            export const NRO_GUIA = "NRO_GUIA";
            export const DEP = "DEP";
            export const PRO = "PRO";
            export const DIS = "DIS";
        }

        // IMPORTACION VIAJES
        export namespace VIAJE {
            export const COD_VIA = "COD_VIA";
            export const HOR_EST = "HOR_EST";
            export const PES_KG = "PES_KG";
            export const VOL_M3 = "VOL_M3";
            export const REO_RUT = "REO_RUT";
            export const ADI_PUN = "ADI_PUN";
            export const NOT_TRA = "NOT_TRA";
            export const NOT_CLI = "NOT_CLI";
            export const COD_ALM = "COD_ALM";
            export const COD_EMP_TRA = "COD_EMP_TRA";
            export const PLA_UNI = "PLA_UNI";
            export const LIC_TRA = "LIC_TRA";
            export const HOR_CIT = "HOR_CIT";
            export const COD_RAS = "COD_RAS";
            export const NRO_TAR = "NRO_TAREA";
            export const TIP_TAR = "TIP_TAR";
            export const BUL = "BUL";
            export const PES = "PES";
            export const VOL = "VOL";
            export const REF_1 = "REF_1";
            export const REF_2 = "REF_2";
            export const NRO_PED = "NRO_PED";
            export const DEB_COB = "DEB_COB";
            export const NRO_GUI = "NRO_GUI";
            export const TIP_PAG = "TIP_PAG";
            export const PAG = "PAG";
            export const DET_GUI = "DET_GUI";            
            export const NRO_PED_ALT = "NRO_PED_ALT";       
            export const TIPO_DOCUMENTO = "TIP_DOC_GUI";     
            export const FECHA_EMISION = "FEC_EMI_GUI";            
            export const ACOPL_1 = "ACOPL1";
            export const ACOPL_2 = "ACOPL2";
            export const COD_PROD = "COD_PROD";
        }
    }


    export namespace CONFIGURACION {

        // CONFIGURACION SENSORES
        export namespace SENSORES {

            export namespace ACELERACION {
                export const CONF_SENS_ACELERACION = "SENS_A";
                export const MIN = 0;
                export const MAX = 150;
            }
            export namespace HUMEDAD {
                export const CONF_SENS_HUMEDAD = "SENS_H";
                export const MIN = 0;
                export const MAX = 100;
            }
            export namespace LUZ {
                export const CONF_SENS_LUZ = "SENS_L";
                export const MIN = 0;
                export const MAX = 100;
            }
            export namespace MAGNETISMO {
                export const CONF_SENS_MAGNETISMO = "SENS_M";
            }
            export namespace TEMPERATURA {
                export const CONF_SENS_TEMPERATURA = "SENS_T";
                export const MIN = -50;
                export const MAX = 50;
            }
        }

        export namespace COMPANIA{
            export const GESTION_SEDE_UNICA = "GEST_SED_UNICA";
        }
    }


    /* TIPO ZONA */
    export namespace ZONA {
        export const PROVINCIA = 0;
        export const LIMA = 1;
    }  

    /* TIPO FECHA UTILIZADO PARA BUSQUEDA EN REPORTE */
    export namespace TIPO_FECHA_VIAJE {
        export const FECHA_ESTIMADA_INICIO = 1;
        export const FECHA_PROGRAMACION = 2;
        export const FECHA_REAL_ATENCION = 3;
        export const FECHA_HORA_CARGA = 4;
        export const FECHA_HORA_REAL_INICIO = 5;
        export const FECHA_HORA_REAL_FIN = 6;
        export const FECHA_HORA_FACTURACION = 7;
  
    }

    /* SEVERIDAD */
    export namespace SEVERIDAD {
        export const SUCCESS = "success";
        export const WARN = "warn";
        export const ERROR = "error";
        export const INFO = "info";
    }

    /* ESTADOS GENERALES */
    export namespace FACTURACION{
        export namespace ESTADO{
            export const PENDIENTE: number = 1;
            export const POR_FACTURAR: number = 2;
            export const FACTURADO: number = 3;
        }
    }
    export namespace COBRANZA{
        export namespace ESTADO{
            export const TOTAL: number = 1;
            export const PARCIAL: number = 2;
            export const COBRADO: number = 3;
            export const ANULADO: number = 4;
        }
    }

    // MOTIVO
    export namespace MOTIVO{
        export namespace ESTADO{
            export const ACTIVO: number = 1;
            export const INACTIVO: number = 2;
        }
    }

    export namespace PERIODO{
        export const DIARIO = "1";
        export const MENSUAL = "2";
    }

    /* TIPO DE SENSORES */
    export namespace SENSOR{
        export const TEMPERATURA = "T";
        export const HUMEDAD = "H";
        export const LUZ = "L";
        export const MAGNETISMO = "M";
        export const ACELERACION = "A";

    }

    /*EMPRESA TRANSPORTE*/
    export namespace EMPRESA_TRANSPORTE{
        export namespace HUB{
            export const SI: string = "SI"; 
            export const NO: string = "NO"; 
        }
    }

    export namespace UNIDADES_MEDIDA{
        export const ACELERACION = "G";
        export const HUMEDAD = "%";
        export const LUZ = "lux";
        export const MAGNETISMO = "";
        export const TEMPERATURA = "°C";
    }

    //Estados SMS
    export namespace ESTADOS_SMS{
        export const ENVIADO = "ENVIADO";
        export const INVALIDO = "invalid_number";
        export const ESTADO_SMS_ENVIADO = "Exitoso";
        export const ESTADO_SMS_FALLIDO = "Fallido";
    }
    //Tipo Contacto
    export namespace TIPO_CONTACTO_SMS{
            export const CLIENTE_FINAL = "NOTI_SMS_CLIENTE_FINAL";
            export const EMPRESA_TRANSPORTE = "NOTI_SMS_EMPRESA_TRANSPORTE";
            export const CONTACTO_SEDE = "NOTI_SMS_CONTACTO_SEDE";
            export const CLIENTE_FINAL_TIPO = "Cliente final";
            export const EMPRESA_TRANSPORTE_TIPO = "Empresa de transporte";
            export const CONTACTO_SEDE_TIPO = "Contacto - sede";
    }

    //Tipo HUB
    export namespace RUTA_HUB{
        export const CON_ALMACEN: number = 1;
        export const SIN_ALMACEN: number = 0;
    }

    export namespace PRODUCTO{
        export const RESANE: number = 1;
        export const SOBRANTE: number = 2;
    }

    export namespace ENTIDAD{ 
        export const TAREA: string = "T" 
        export const VIAJE: string = "V" 
    } 

    export namespace MOTIVO_ESTADO{ 
        export const CAMBIO_WEB_DESHABILITADO = "CAMBIO_WEB_DESHABILITADO"; 
    } 
 
    /* TIPO LIQUIDACION */
    export namespace TIPO_LIQUIDACION{
        export const COBRANZA: number = 1;
        export const ENTREGA:  number = 2;
        export const RECOJO:   number = 3;
    } 

    export namespace TIPO_ENVIO_CORREO{
        export const TIPO_ENVIO_CORREO_RECUPERAR_CONTRASENA: number = 1;
        export const TIPO_ENVIO_CORREO_GENERAR_CONTRASENA:  number = 2;
        export const TIPO_ENVIO_CORREO_CAMBIO_CONTRASENA:   number = 3;
        export const TIPO_ENVIO_CORREO_RECUPERAR_CONTRASENA_OLVIDO_CLAVE: number = 4;
    } 

    export namespace ETIQUETA_CONFIGURABLE{
        export const CONF_ETIQUETA_MARCA : string = 'conf_etiqueta_marca';
    }

    export const COLOR_LIST = ['#557BFF', '#1B3BA9', '#044F40', '#7F0FA7', '#C700A7', '#ED4949', '#995618', '#F27400', '#373634', '#00B9D2',
    '#e824b6', '#2d3582', '#802fb7', '#753837', '#521006', '#da595d', '#8fc76b', '#7604ed', '#39296a', '#1e9463',
    '#819681', '#8a6608', '#4a2eae', '#50b6ee', '#7771a4', '#1a614f', '#9139f3', '#9579bc', '#2e7b8a', '#f01655',
    '#ce63c1', '#26208d', '#4e486c', '#80eeb0', '#be153e', '#687e2e', '#f96aa5', '#198fdc', '#4f737e', '#e93dff',
    '#8e570a', '#af5b90', '#8559c6', '#8b969a', '#768c46', '#c7424b', '#dd703e', '#3aa6b1', '#849d36', '#73377d'
    ];

    export namespace TIPO_OPERACION{
        export const TIPO_VIAJE_INDIVIDUAL = 1;
        export const TIPO_RANGO_FECHA = 2;
    }

    export namespace TIPO_EVIDENCIA{
        export const ENTREGA : number = 1;
        export const RECOJO : number = 2;
    }

}
