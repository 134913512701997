export class Operacion{
    operacionId?: number;
    codigo?: string;
    descripcion?: string;

    copiar(objeto: any){
        this.operacionId = objeto.operacionId;
        this.codigo = objeto.codigo;
        this.descripcion = objeto.descripcion;
    }
}