import { Injectable } from '@angular/core';
import { HttpClient2 } from './http-client.service';
import { Operacion } from '@shared/models/operacion';
import { Observable } from 'rxjs/Observable';
import { IResultado } from '@shared/commons/interfaces';

@Injectable({
  providedIn: 'root'
})
export class OperacionService {

  ciaId: string;
  constructor(private http: HttpClient2) {
    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));
    this.ciaId = currentUser.esEmpresaTransporteAdministrador? "/"+currentUser?.companiaPadre: "/"+currentUser.compania.companiaId;  
   }

  listar(): Observable<Array<Operacion>>{
    return this.http.listar(this.ciaId+"/operacion").map(
      (data: IResultado) => {
        let list = Array<Operacion>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new Operacion();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  consultar(data): Observable<Array<Operacion>>{
        return this.http.consultar(this.ciaId+"/operacion/consultar", data).map(
           (data)=>{
            let list = Array<Operacion>();
            let obj : Operacion;
            if(data){
              for (const d of data.resultado) {
                obj = new Operacion();
                obj.copiar(d);
                list.push(obj);
              }
            }
            return list;
          }
        );
  }

    obtener(id : number) : Observable<Operacion>{
      return this.http.obtener(this.ciaId+"/operacion/"+id).map(
        (data: IResultado) => {
          if(data){
            let obj = new Operacion();
            obj.copiar(data.resultado);
            return obj;
          }
      });
    }

      addOrEdit(operacion: Operacion){
        if (operacion.operacionId) {
          return this.http.modificar(this.ciaId+"/operacion", operacion);
        } else {     
          return this.http.registrar(this.ciaId+"/operacion", operacion);
        }
      }

      eliminar(operacionId : number, funcionalidad?: String){
        return this.http.eliminar(this.ciaId+"/operacion/"+operacionId, { funcionalidad });
      }
}
