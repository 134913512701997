import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import * as moment  from 'moment';
import { SelectItem } from 'primeng/primeng';

import { C , ISelectObject, obtenerEtiquetaConfigurable} from '../commons';
import { Usuario, LogActividad, Funcionalidad, LogSession } from '../../shared';


@Injectable()
export class GeneralProvider {
    public storage: any;

    etiquetas: any = {};
    
    constructor(private translate : TranslateService) {
        this.loadEtiquetas();
    }

    loadEtiquetas(){
        this.etiquetas = JSON.parse(localStorage.getItem("etiquetas"));
    }

    getConfiguracionPersonalizada(codigo): string{
        let etiquetaDefaultPais;
        let etiquetaConfigurable = obtenerEtiquetaConfigurable(codigo, this.etiquetas);
        if(codigo === C.ETIQUETA_CONFIGURABLE.CONF_ETIQUETA_MARCA){
            etiquetaDefaultPais = this.translate.instant("WEB.mantenimiento.marca.titulo")
        }
        return etiquetaConfigurable != undefined ? etiquetaConfigurable : etiquetaDefaultPais;
    }

    getListaTipoEvidencia() : SelectItem[]{
        let listTipoMotivo : SelectItem[] = [];
        this.translate.get(['COMMON.constantes.tipo-evidencia']).subscribe(translations =>{
            if(translations){
                listTipoMotivo.push(
                    {label: this.translate.instant("COMMON.constantes.tipo-evidencia.texto"), value: C.EVIDENCIA.TIPO.TEXTO}, 
                    {label: this.translate.instant("COMMON.constantes.tipo-evidencia.texto-imagenes"), value: C.EVIDENCIA.TIPO.TEXTO_IMAGENES}            
                );
            }
        });
        return listTipoMotivo;
    }

    getListaTipoTarea() : SelectItem[]{
        let listTarea : SelectItem[] = [];
        this.translate.get(['COMMON.constantes']).subscribe(translations => {
            if(translations){
                listTarea.push(
                    {
                        label: this.translate.instant("COMMON.constantes.tipo-tarea.entrega-label"), 
                        value: C.TAREA.TIPO.ENTREGA
                    }, 
                    {
                        label: this.translate.instant("COMMON.constantes.tipo-tarea.recojo-label"), 
                        value: C.TAREA.TIPO.RECOJO
                    },
                    {
                        label: this.translate.instant("COMMON.constantes.tipo-tarea.retorno-label"),
                        value: C.TAREA.TIPO.RETORNO
                    }            
                 );
            }  
          }); 
        return listTarea;
    }

    getListaZona() : SelectItem[]{
        let listZona : SelectItem[] = [];
        this.translate.get(['WEB.dashboard.diario.filtros.zona']).subscribe(translations => {
            if(translations){
              listZona.push(
                    { label: this.translate.instant("WEB.dashboard.diario.filtros.zona.lima"), value: C.ZONA.LIMA }, 
                    { label: this.translate.instant("WEB.dashboard.diario.filtros.zona.provincia"), value : C.ZONA.PROVINCIA }          
                 );
            }  
          }); 
        return listZona;
    }

    getListaTiposFecha() : SelectItem[]{
        let listTipoFecha : SelectItem[] = [];
        this.translate.get(['WEB.reporte.busqueda.tipos-fecha-viaje']).subscribe(translations => {
            if(translations){
                listTipoFecha.push(
                    { label: this.translate.instant("WEB.reporte.busqueda.tipos-fecha-viaje.fecha-estimada-inicio"), value: C.TIPO_FECHA_VIAJE.FECHA_ESTIMADA_INICIO }, 
                    { label: this.translate.instant("WEB.reporte.busqueda.tipos-fecha-viaje.fecha-programacion"), value : C.TIPO_FECHA_VIAJE.FECHA_PROGRAMACION },          
                    { label: this.translate.instant("WEB.reporte.busqueda.tipos-fecha-viaje.fecha-real-atencion"), value : C.TIPO_FECHA_VIAJE.FECHA_REAL_ATENCION }               
                 );
            }  
          }); 
        return listTipoFecha;
    }

    getListaMeses(cantidadMeses : number) : SelectItem[]{
        let listMeses : SelectItem[] = [];
        let lang = this.translate.currentLang;
        let now = moment().locale(lang);

        while(cantidadMeses >=1) {             
            listMeses.push(
                {
                    label: now.format("MMM YYYY"), 
                    value: now.get('year')+"-"+now.format("MM")+"-01"
                }
            );
            now = now.subtract(1, 'M');;
            cantidadMeses--;            
        }

        return listMeses;
    }

    getListaEstadosViaje() : SelectItem[]{
        this.loadEtiquetas();
        let listEstados : SelectItem[] = [];

        listEstados.push(
                {
                    label: this.translate.instant(this.etiquetas['estado_viaje_programado']), 
                    value: C.VIAJE.ESTADO.PROGRAMADO
                }, 
                {
                    label: this.translate.instant(this.etiquetas['estado_viaje_en_apt']), 
                    value: C.VIAJE.ESTADO.APT
                },
                {
                    label: this.translate.instant(this.etiquetas['estado_viaje_en_ruta']), 
                    value: C.VIAJE.ESTADO.EN_RUTA
                },
                {
                    label: this.translate.instant(this.etiquetas['estado_viaje_finalizado']), 
                    value: C.VIAJE.ESTADO.FINALIZADO
                }
        );

        return listEstados;
    }

    getListaEstadosTarea() : SelectItem[]{
        let listEstados : SelectItem[] = [];

        listEstados.push(
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.pendiente"), 
                    value: C.TAREA.ESTADO.PENDIENTE
                }, 
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.en-apt"), 
                    value: C.TAREA.ESTADO.EN_APT
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.en-ruta"), 
                    value: C.TAREA.ESTADO.EN_RUTA
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.en-espera"), 
                    value: C.TAREA.ESTADO.EN_ESPERA
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.en-cliente"), 
                    value: C.TAREA.ESTADO.EN_CLIENTE
                }, 
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.inicio-descarga"), 
                    value: C.TAREA.ESTADO.REALIZANDO_TAREA
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.terminado-total"), 
                    value: C.TAREA.ESTADO.TERMINADO_TOTAL
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.terminado-parcial"), 
                    value: C.TAREA.ESTADO.TERMINADO_PARCIAL
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.rechazado"), 
                    value: C.TAREA.ESTADO.RECHAZADO
                }, 
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.no-realizado"), 
                    value: C.TAREA.ESTADO.NO_REALIZADO
                }
        );
        return listEstados;
    }
    
    getListaEstadosTareaFinalizados() : SelectItem[]{
        let listEstados : SelectItem[] = [];

        listEstados.push(
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.terminado-total"), 
                    value: C.TAREA.ESTADO.TERMINADO_TOTAL
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.terminado-parcial"), 
                    value: C.TAREA.ESTADO.TERMINADO_PARCIAL
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.rechazado"), 
                    value: C.TAREA.ESTADO.RECHAZADO
                }, 
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.no-realizado"), 
                    value: C.TAREA.ESTADO.NO_REALIZADO
                }
        );
        return listEstados;
    }

    getListaEstadosTipoOperacion() : SelectItem[]{
        let listTipoOperacion : SelectItem[] = [];

        listTipoOperacion.push(
                {
                    label: this.translate.instant("WEB.seguridad.cambio-estado-viaje.tipo-operacion-viaje"), 
                    value: C.TIPO_OPERACION.TIPO_VIAJE_INDIVIDUAL
                },
                {
                    label: this.translate.instant("WEB.seguridad.cambio-estado-viaje.tipo-operacion-rango-fecha"), 
                    value: C.TIPO_OPERACION.TIPO_RANGO_FECHA
                }
        );
        return listTipoOperacion;
    }

    getListaEstadosTareaDevoluciones() : SelectItem[]{
        let listEstados : SelectItem[] = [];

        listEstados.push(              
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.terminado-parcial"), 
                    value: C.TAREA.ESTADO.TERMINADO_PARCIAL
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.rechazado"), 
                    value: C.TAREA.ESTADO.RECHAZADO
                },
                {
                    label: this.translate.instant("COMMON.constantes.estados-tarea.no-realizado"), 
                    value: C.TAREA.ESTADO.NO_REALIZADO
                }                
        );
        return listEstados;
    }

    getListaEstadosTiempo() : SelectItem[]{
        let listEstados : SelectItem[] = [];

        listEstados.push(
                {
                    label: this.translate.instant("WEB.control.estados-viaje.constantes.estado-a-tiempo"), 
                    value: C.VIAJE.TIEMPO.A_TIEMPO
                }, 
                {
                    label: this.translate.instant("WEB.control.estados-viaje.constantes.estado-riesgo"), 
                    value: C.VIAJE.TIEMPO.RIESGO
                },
                {
                    label: this.translate.instant("WEB.control.estados-viaje.constantes.estado-atraso"), 
                    value: C.VIAJE.TIEMPO.ATRASO
                },
                {
                    label: this.translate.instant("WEB.control.estados-viaje.constantes.estado-realizado"), 
                    value: C.VIAJE.TIEMPO.REALIZADO
                }
        );

        return listEstados;
    }

    getListaTipoAcceso() : SelectItem[]{
        let listAcceso : SelectItem[] = [];

        listAcceso.push(
                {
                    label: this.translate.instant("COMMON.constantes.tipo-acceso.web-label"), 
                    value: this.translate.instant("COMMON.constantes.tipo-acceso.web-value")
                }, 
                {
                    label: this.translate.instant("COMMON.constantes.tipo-acceso.movil-label"), 
                    value: this.translate.instant("COMMON.constantes.tipo-acceso.movil-value")
                }
        );

        return listAcceso;
    }

    getListaEstadosFacturacion() : SelectItem[]{
        let listEstadoFacturacion : SelectItem[] = [];
        this.translate.get(['WEB.control.estados-facturacion.resumen-facturacion']).subscribe(translations => {
            if(translations){
                listEstadoFacturacion.push(
                    { label:  this.translate.instant("WEB.control.estados-facturacion.resumen-facturacion.pendiente"),
                        value: C.ESTADO_VIAJE_FACTURACION.ESTADO.PENDIENTE }, 
                    { label:  this.translate.instant("WEB.control.estados-facturacion.resumen-facturacion.por-facturar"),
                        value: C.ESTADO_VIAJE_FACTURACION.ESTADO.POR_FACTURAR },
                    { label:  this.translate.instant("WEB.control.estados-facturacion.resumen-facturacion.facturado"),
                        value: C.ESTADO_VIAJE_FACTURACION.ESTADO.FACTURADO }
                 );
            }  
          }); 
        return listEstadoFacturacion;
    }

    getListaEstadosSolicitudPedido() : SelectItem[]{
        let listEstadoFacturacion : SelectItem[] = [];
        this.translate.get(['WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido']).subscribe(translations => {
            if(translations){
                listEstadoFacturacion.push(
                    { label:  this.translate.instant("WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.recibido"),
                        value: C.ESTADO_SOLICITUD_PEDIDO.ESTADO.RECIBIDO },
                    { label:  this.translate.instant("WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.digitado"),
                        value: C.ESTADO_SOLICITUD_PEDIDO.ESTADO.DIGITADO },
                    { label:  this.translate.instant("WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.aprobado"),
                        value: C.ESTADO_SOLICITUD_PEDIDO.ESTADO.APROBADO },
                    { label:  this.translate.instant("WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.facturado"),
                        value: C.ESTADO_SOLICITUD_PEDIDO.ESTADO.FACTURADO },
                    { label:  this.translate.instant("WEB.control.estados-solicitud-pedido.resumen-solicitud-pedido.despachado"),
                        value: C.ESTADO_SOLICITUD_PEDIDO.ESTADO.DESPACHADO },
                 );
            }  
          });
        return listEstadoFacturacion;
    }
    
    //Plugins para Graficos
    getPluginPorcentaje() : Object{
        return {
            afterDatasetsDraw: function(chart, easing) {
                // To only draw at the end of animation, check for easing === 1
                var ctx = chart.ctx;

                chart.data.datasets.forEach(function (dataset, i) {
                    var meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach(function(element, index) {
                            // Draw the text in black, with the specified font
                            ctx.fillStyle = 'rgb(0, 0, 0)';

                            var fontSize = 10;
                            var fontStyle = 'normal';
                            var fontFamily = 'Arial';
                            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                            // Just naively convert to string for now
                            var dataString = dataset.data[index].toString()+" %";

                            // Make sure alignment settings are correct
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';

                            var padding = 5;
                            var position = element.tooltipPosition();
                            ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
                        });
                    }
                });
            }
        };
    }

    //Muestra el Valor y el Porcentaje ( 5 | 10%)
    getPluginHorizontalValorPorcentaje() : Object{
        return {
            afterDatasetsDraw: function(chart, easing) {
                // To only draw at the end of animation, check for easing === 1
                var ctx = chart.ctx;

                chart.data.datasets.forEach(function (dataset, i) {
                    // Obtenemos el valor total
                    var total = 0;
                    for(var j of dataset.data){
                        total+=j;
                    }

                    var meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach(function(element, index) {
                            // Draw the text in black, with the specified font
                            ctx.fillStyle = 'rgb(0, 0, 0)';

                            var fontSize = 10;
                            var fontStyle = 'normal';
                            var fontFamily = 'Arial';
                            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                            // Just naively convert to string for now
                            var valor = dataset.data[index];
                            var porcentaje = total == 0 ? 0 : Math.round(valor*100/total);//Math.round(valor*10000/total) / 100;
                            var dataString1 = valor.toString();
                            var dataString2 = porcentaje.toString()+"%";

                            // Make sure alignment settings are correct
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';

                            var position = element.tooltipPosition();
                            ctx.fillText(dataString1+" - "+dataString2, (position.x+fontSize*2), position.y);
                        });
                    }
                });
            }
        };
    }

    //Muestra el Valor en un grafico de barras horizontal ( 5 )
    getPluginHorizontalValor() : Object{
        return {
            afterDatasetsDraw: function(chart, easing) {
                // To only draw at the end of animation, check for easing === 1
                var ctx = chart.ctx;

                chart.data.datasets.forEach(function (dataset, i) {
                    var meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach(function(element, index) {
                            // Draw the text in black, with the specified font
                            ctx.fillStyle = 'rgb(0, 0, 0)';

                            var fontSize = 10;
                            var fontStyle = 'normal';
                            var fontFamily = 'Arial';
                            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                            // Just naively convert to string for now
                            var valor = dataset.data[index];
                            var dataString1 = valor.toString();

                            // Make sure alignment settings are correct
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';

                            var position = element.tooltipPosition();
                            ctx.fillText(dataString1, (position.x+fontSize), position.y);
                        });
                    }
                });
            }
        };
    }

    getPluginTopData() : Object{
        return {
            afterDatasetsDraw: function(chart, easing) {
                // To only draw at the end of animation, check for easing === 1
                var ctx = chart.ctx;

                chart.data.datasets.forEach(function (dataset, i) {
                    var meta = chart.getDatasetMeta(i);
                    if (!meta.hidden) {
                        meta.data.forEach(function(element, index) {
                            // Draw the text in black, with the specified font
                            ctx.fillStyle = 'rgb(0, 0, 0)';
                            
                            var fontSize = 10;
                            var fontStyle = 'normal';
                            var fontFamily = 'Arial';
                            ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                            // Just naively convert to string for now
                            var dataString = dataset.data[index].toString();

                            // Make sure alignment settings are correct
                            ctx.textAlign = 'center';
                            ctx.textBaseline = 'middle';

                            var padding = 5;
                            var position = element.tooltipPosition();
                            ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
                        });
                    }
                });
            }
        };
    }

    getDataSensores(): Object{
        return {
            aceleracion : {
                min : C.CONFIGURACION.SENSORES.ACELERACION.MIN,
                max : C.CONFIGURACION.SENSORES.ACELERACION.MAX,
                unidad_medida : " (" + C.UNIDADES_MEDIDA.ACELERACION + ")"
            },
            humedad : {
                min : C.CONFIGURACION.SENSORES.HUMEDAD.MIN,
                max : C.CONFIGURACION.SENSORES.HUMEDAD.MAX,
                unidad_medida : " (" + C.UNIDADES_MEDIDA.HUMEDAD + ")"
            },
            luz : {
                min : C.CONFIGURACION.SENSORES.LUZ.MIN,
                max : C.CONFIGURACION.SENSORES.LUZ.MAX,
                unidad_medida : " (" + C.UNIDADES_MEDIDA.LUZ + ")"
            },
            magnetismo : {
                unidad_medida : "" + C.UNIDADES_MEDIDA.MAGNETISMO + ""
            },
            temperatura : {
                min : C.CONFIGURACION.SENSORES.TEMPERATURA.MIN,
                max : C.CONFIGURACION.SENSORES.TEMPERATURA.MAX,
                unidad_medida : " (" + C.UNIDADES_MEDIDA.TEMPERATURA + ")"
            }
        }
    }

    static getDataSensores(): Object {
        return {
            aceleracion: {
                min: C.CONFIGURACION.SENSORES.ACELERACION.MIN,
                max: C.CONFIGURACION.SENSORES.ACELERACION.MAX,
                unidad_medida: " (" + C.UNIDADES_MEDIDA.ACELERACION + ")",
                unidad_medida_raw: C.UNIDADES_MEDIDA.ACELERACION
            },
            humedad: {
                min: C.CONFIGURACION.SENSORES.HUMEDAD.MIN,
                max: C.CONFIGURACION.SENSORES.HUMEDAD.MAX,
                unidad_medida: " (" + C.UNIDADES_MEDIDA.HUMEDAD + ")",
                unidad_medida_raw: C.UNIDADES_MEDIDA.HUMEDAD
            },
            luz: {
                min: C.CONFIGURACION.SENSORES.LUZ.MIN,
                max: C.CONFIGURACION.SENSORES.LUZ.MAX,
                unidad_medida: " (" + C.UNIDADES_MEDIDA.LUZ + ")",
                unidad_medida_raw: C.UNIDADES_MEDIDA.LUZ
            },
            magnetismo: {
                unidad_medida: "" + C.UNIDADES_MEDIDA.MAGNETISMO + "",
                unidad_medida_raw: C.UNIDADES_MEDIDA.MAGNETISMO
            },
            temperatura: {
                min: C.CONFIGURACION.SENSORES.TEMPERATURA.MIN,
                max: C.CONFIGURACION.SENSORES.TEMPERATURA.MAX,
                unidad_medida: " (" + C.UNIDADES_MEDIDA.TEMPERATURA + ")",
                unidad_medida_raw: C.UNIDADES_MEDIDA.TEMPERATURA
            }
        }
    }
    
}