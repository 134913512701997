import { Pipe, PipeTransform } from '@angular/core';
import { C } from '@shared/commons';

import memo from 'memo-decorator';

@Pipe({
  name: 'estadoTareaToString'
})
export class EstadoTareaToStringPipe implements PipeTransform {

  @memo()
  transform(estado: number, etiquetas: any): string {
      if(estado!=null){
        switch(estado){
            case C.TAREA.ESTADO.PENDIENTE:
                return etiquetas['estado_tarea_pendiente'];
            case C.TAREA.ESTADO.EN_APT:
                return etiquetas['estado_tarea_en_apt'];
            case C.TAREA.ESTADO.EN_RUTA:
                return etiquetas['estado_tarea_en_ruta'];
            case C.TAREA.ESTADO.EN_ESPERA:
                return etiquetas['estado_tarea_en_espera'];
            case C.TAREA.ESTADO.EN_CLIENTE:
                return etiquetas['estado_tarea_en_cliente'];
            case C.TAREA.ESTADO.REALIZANDO_TAREA:
                return etiquetas['estado_tarea_inicio_descarga'];
            case C.TAREA.ESTADO.TERMINADO_TOTAL:
                return etiquetas['estado_tarea_terminado_total'];
            case C.TAREA.ESTADO.TERMINADO_PARCIAL:
                return etiquetas['estado_tarea_terminado_parcial'];
            case C.TAREA.ESTADO.RECHAZADO:
                return etiquetas['estado_tarea_rechazado'];
            case C.TAREA.ESTADO.NO_REALIZADO:
                return etiquetas['estado_tarea_no_realizado'];
            case C.TAREA.ESTADO.EN_CAMINO:
                return etiquetas['estado_tarea_en_camino'];
            // case C.TAREA.ESTADO.EN_HUB:
            //     return etiquetas['estado_tarea_en_hub'];    
        }
    }
    return "";
  }

}
