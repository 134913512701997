import { Injectable } from '@angular/core';
import { IResultado } from '@shared/commons';
import { Compania } from '@shared/models';
import { FuncionalidadPersonalizacion } from '@shared/models/funcionalidadPersonalizacion';
import { Observable } from 'rxjs';
import { HttpClient2 } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class FuncionalidadPersonalizacionService {

  constructor(private http : HttpClient2) { }

  consultar(funcionalidadPersonalizada: FuncionalidadPersonalizacion) : Observable<Array<FuncionalidadPersonalizacion>>{

    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));    
    let companiaId = currentUser.compania.companiaId; 

    funcionalidadPersonalizada.compania = new Compania();
    // Si no es Administrador y es Empresa de Transporte Administrador, se consulta la personalización de la compañía padre
    if (!currentUser.esAdministrador && currentUser.esEmpresaTransporteAdministrador) {      
      funcionalidadPersonalizada.compania.companiaId = currentUser.companiaPadre ?? companiaId;
    } else {
      funcionalidadPersonalizada.compania.companiaId = companiaId;
    }

    return this.http.consultar("/compania/"+companiaId+"/funcionalidad-personalizada/consultar", funcionalidadPersonalizada)
      .map((data: IResultado) => {
        
        let list = Array<FuncionalidadPersonalizacion>();
        let obj;
        if(data){
          for (const d of data.resultado) {
            obj = new FuncionalidadPersonalizacion();
            obj.copiar(d);
            list.push(obj);
          }
        }
        return list;
    });
  }

  guardar(body: any) : Observable<any>{

    let currentUser  = JSON.parse(localStorage.getItem("currentUser"));    
    let companiaId = currentUser.compania.companiaId; 

    return this.http.registrar("/compania/"+companiaId+"/funcionalidad-personalizada/guardar", body)
      .map((data: IResultado) => {
        return data.resultado;
    });
  }

}
